import Swiper from "swiper";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

// Generic Swiper Initialization for Logo Carousel
function initializeSwiper(selector, options) {
  const container = document.querySelector(selector);
  if (container) {
    const slides = container.querySelectorAll(".swiper-slide");
    if (slides.length > 1) {
      return new Swiper(selector, options);
    }
  }
  return null;
}

// Initialize Logo Carousel
window.logoCarousel = document.querySelector(".logo-carousel")
  ? initializeSwiper(".logo-carousel", {
      modules: [Pagination, Autoplay],
      slidesPerView: 2,
      spaceBetween: 20, // Adjust space between slides
      loop: true, // Enable looping
      speed: 1000, // Set a smooth transition speed
      autoplay: {
        delay: 3000, // Slide every 3 seconds
        disableOnInteraction: false, // Ensure autoplay continues after interaction
      },
      breakpoints: {
        640: { slidesPerView: 4 },
        768: { slidesPerView: 5 },
      },
      grabCursor: true, // Cursor changes to grab when hovering over slides
      pagination: {
        el: ".logo-carousel-pagination",
        clickable: true,
        dynamicBullets: true,
      },
    })
  : null;
