// Starting up Alpine 
import Alpine from "alpinejs"
import focus from '@alpinejs/focus'
import ui from '@alpinejs/ui'

// Masonry + ImagesLoaded
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

//  Initialize Masonry on DOMContentLoaded
document.addEventListener('DOMContentLoaded', () => {
  const grids = document.querySelectorAll('.js-masonry-grid');
  grids.forEach(grid => {
    imagesLoaded(grid, () => {
      new Masonry(grid, {
        itemSelector: '.js-masonry-item',
        columnWidth: '.js-masonry-sizer',
        percentPosition: true
      });
    });
  });
});

window.Alpine = Alpine
Alpine.plugin(focus)
Alpine.plugin(ui)
Alpine.start();

// Stops JS errors to appear on the Console
require("console-polyfill/index")
